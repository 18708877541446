import { useForm, useFieldArray, useWatch } from 'react-hook-form';
import styles from './JourneyBuilder.module.scss';
import { Button, EditableTitle } from '../../../../../../components/FormComponents';
import { IconButton } from '../../../../../../components/IconButton/IconButton';
import { WelcomeScreenSettings } from '../WelcomeScreenSettings/WelcomeScreenSettings';
import { useEffect } from 'react';
import { JourneyBlockConfig } from './../JourneyBlockConfig/JourneyBlockConfig';
import { CollapsibleGroup } from '../../../../../../components/CollapsibleBlock/CollapsibleBlock';
import FeatherIcon from 'feather-icons-react';
import cn from 'classnames';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useState } from 'react';
import { ConfirmationModal } from 'components/ConfirmationModal/ConfirmationModal';
import { JourneyConfig } from '../JourneyConfig/JourneyConfig';
import { JourneyPublicSettings } from '../JourneyPublicSettings/JourneyPublicSettings';
import { JourneyPassingMarks } from '../JourneyPassingMarks/JourneyPassingMarks';

export const JourneyBuilder = ({
  journey,
  blocks,
  onSave = () => {},
  onDelete = () => {},
  onClose = () => {},
  stakeholderTags,
  error = () => {},
  onCustomizeJourney = () => {},
  brands,
  updateJourney,
}) => {

  const { register, control, setError, reset, clearErrors, unregister, setValue, handleSubmit, watch, formState: { errors } } = useForm();
  const { fields: journeyBlocks, append, swap } = useFieldArray({ control, name: 'journeyBlocksAttributes', keyName: 'key' });

  const [filteredBlocks , setFilteredBlocks] = useState(blocks)
  const watchJourneyBlocks = watch('journeyBlocksAttributes');
    let errorCount = 0
    errors?.journeyBlocksAttributes?.forEach((attribute)=>{
      errorCount = attribute?.actionsAttributes ? errorCount + 1 : errorCount
    })
    if ( errors?.journeyBlocksAttributes?.length && !errorCount ){
      clearErrors('journeyBlocksAttributes')
    }

  const [openModal, setOpenModal] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);

  // Set journey
  useEffect(() => {
    reset(journey)
  }, [journey])


  useEffect(()=>{
    const bundleBlocks = blocks?.filter((block)=> block?.bundleId)  
    const bundleBlockIds = bundleBlocks?.map((block)=>block.id)
    let bundles  = 0
    watchJourneyBlocks?.forEach((block)=>{
      if(bundleBlockIds?.includes(block.blockId) ){
        if (!block?.hasOwnProperty('._destroy')){
          bundles = bundles + 1
        }
      }
    })
    if (bundles){
      setFilteredBlocks(blocks?.filter((block)=> ! block?.bundleId))
    }else{
      setFilteredBlocks(blocks)
    }
  },[journeyBlocks])

  const onSubmit = (data) => {
    if(data.passingMarks){
      data.passingMarks = parseInt(data.passingMarks)
    }
    // Delete __typename (grapqhl hack)
    delete data.__typename;

    if(!data.journeyCustomizationAttributes?.useMedia) {
      data.journeyCustomizationAttributes.useMedia = false;
      data.journeyCustomizationAttributes.mediaType = 'video';
    }

    if(!data.journeyCustomizationAttributes.useDashboardHeadings) data.journeyCustomizationAttributes.useDashboardHeadings = false;

    if(!data.journeyCustomizationAttributes.useWelcomeScreenHeadings) {
      data.journeyCustomizationAttributes.useWelcomeScreenHeadings = false;
    }

    if(!data.journeyCustomizationAttributes.usePublicUrlAccess) {
      data.journeyCustomizationAttributes.usePublicUrlAccess = false;
    }

    if(data.journeyCustomizationAttributes) delete data.journeyCustomizationAttributes.image;

    // Welcome screen data type
    if(data.journeyCustomizationAttributes) delete data.journeyCustomizationAttributes.__typename;

    // Journey blocks data type
    data.journeyBlocksAttributes.forEach(journeyBlock => {
      // Parse boolean
      journeyBlock.required = journeyBlock.required === 'false' ? false : true;
      // Delete typename
      delete journeyBlock.__typename

      journeyBlock?.triggersAttributes?.forEach(trigger => {
        delete trigger?.__typename
        delete trigger?.params?.__typename
        if (trigger.typeOf !== 'before_date' && trigger.typeOf !== 'after_date') {
          delete trigger.params
        }
      })

      journeyBlock?.actionsAttributes?.forEach((action) => {
        delete action?.key
        delete action?.__typename
        action?.actionsStakeholderTagsAttributes?.forEach((stakeholderTagId) => {
          delete stakeholderTagId.__typename
        })
      })
    });

    if(!data.journeyCustomizationAttributes.usePublicUrlAccess && !data.journeyCustomizationAttributes.useWelcomeScreenHeadings && !data.journeyCustomizationAttributes.useDashboardHeadings && data.journeyCustomizationAttributes.id) {
      data.journeyCustomizationAttributes._destroy = true;
    } else if(!data.journeyCustomizationAttributes.useWelcomeScreenHeadings && !data.journeyCustomizationAttributes.useDashboardHeadings && !data.journeyCustomizationAttributes.usePublicUrlAccess) {
      delete data.journeyCustomizationAttributes
    }

    // Save
    onSave(data);
  }

  const addBlock = () => {
    if (blocks.length > 0) {
      const usedBlocks = journeyBlocks.map((jb) => (jb.blockId))
      const unusedBlocks = blocks.filter((e) => (!usedBlocks.includes(e.id)))
      append({
        order: journeyBlocks.filter(journeyBlock => !journeyBlock._destroy).length + 1,
        blockId: unusedBlocks[0].id,
        required: true
      });
      return
    }
    error()
  }

  const handleRemove = (indexToRemove) => {
    let _journeyBlocks = [];
    let order = 1;
    journeyBlocks.forEach((journeyBlock, index) => {
      let _journeyBlock;
      if(index === indexToRemove || journeyBlock._destroy) {
        _journeyBlock = {...journeyBlock, _destroy: true }
      } else {
        _journeyBlock = {...journeyBlock, order: order }
        order++;
      }
      delete _journeyBlock.key;
      if(!_journeyBlock._destroy || _journeyBlock.id) _journeyBlocks.push(_journeyBlock)
    });
    setValue('journeyBlocksAttributes', _journeyBlocks);
  }

  const onDragEnd = ({ source, destination }) => {
    if(!source || !destination) return;

    swap(source.index, destination.index);
    setValue(`journeyBlocksAttributes[${source.index}].order`, source.index + 1);
    setValue(`journeyBlocksAttributes[${destination.index}].order`, destination.index + 1);
  }

  const handleDelete = () => {
    setOpenModal(true);
  }

  const handleBrandUpdate = (settings) => {
    onSave({id: journey.id, brandId: settings.brandId})
    setOpenSettings(false)
  }

  return (
    <>
      <ConfirmationModal
        visible={openModal}
        title='Delete Journey Confirmation'
        subtitle='This Journey will be permanently deleted. This data cannot be recovered'
        confirmationKey={watch('name')}
        confirmationIcon='trash-2'
        onClose={() => { setOpenModal(false) }}
        onConfirmation={onDelete}
        buttonTitle='Delete Journey'
      />
      <JourneyConfig
        visible={openSettings}
        brands={brands}
        journey={journey}
        onClose={() => {setOpenSettings(false)}}
        onSubmit={handleBrandUpdate}
        errors={errors}
        journeyBlocks={watchJourneyBlocks}
        blocks={blocks}
      />
      <form className={cn(styles.root, 'card card-with-border')} onSubmit={handleSubmit(onSubmit)}>
        <div className='card_content'>
          <header className={styles.header}>
            <EditableTitle register={register} name='name' value={watch('name')} />
            <div>
              <IconButton className='margin-right' tip='Journey Settings' icon='settings' onClick={() => {setOpenSettings(true)}}/>
              <IconButton tip='Delete Journey' icon='trash-2' onClick={handleDelete}/>
            </div>
          </header>
          {journey?.incomplete && <div className={styles.error}> <span className={styles.errorText}>This journey cannot be edited as it is currently in-flight</span> </div> }
          <p className={cn(styles.subtitle, 't-subtitle margin-top')}>
            You can add or remove as many blocks as you like to your journey. Using the triggers, you can also set a specific time for when a block is launched
          </p>
          <JourneyPublicSettings journey={journey} blocks={blocks} onCustomizeJourney={onCustomizeJourney} />
          <WelcomeScreenSettings errors={errors} watch={watch} control={control} unregister={unregister} register={register} setValue={setValue} journey={journey} />
          <JourneyPassingMarks journeyIncomplete={journey?.incomplete} watch={watch} setError={setError} clearErrors={clearErrors} errors={errors} journeyBlocks={watchJourneyBlocks} blocks={blocks} register={register} />
        </div>
        <div className={cn('card_content', 'background-secondary', styles.content)}>
          <CollapsibleGroup newStartOpen={true}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId='options'>
                {({ innerRef, droppableProps, placeholder }) => (
                  <div className='w-100' {...droppableProps} ref={innerRef}>
                    {journeyBlocks.map((journeyBlock, index) => {
                    return (
                      (!journeyBlock._destroy) &&
                        <JourneyBlockConfig
                          clearErrors={clearErrors}
                          errors={errors}
                          setValue={setValue}
                          journey={journey}
                          journeyBlockObject={journeyBlock}
                          key={journeyBlock.key}
                          index={index}
                          register={register}
                          control={control}
                          watch={watch}
                          useWatch={useWatch}
                          journeyBlock={watchJourneyBlocks[index]}
                          blocks={filteredBlocks}
                          remove={handleRemove}
                          stakeholderTags={stakeholderTags}
                        />
                    )})}
                    {placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </CollapsibleGroup>
          <Button disabled={journeyBlocks.length === blocks.length} className='w-100' type='secondary-reverse' onClick={addBlock}><FeatherIcon size={18} className='margin-right' icon='plus'/>Add Block</Button>
        </div>
        <div className={cn(styles.footer,'card_footer d-flex justify-content-between')}>
          <Button type='secondary' onClick={onClose}>Cancel</Button>
          <Button disabled={journey?.incomplete} submit>Save</Button>
        </div>
      </form>
    </>
  )
}
