import React from "react";
import { useMutation, useApolloClient, useReactiveVar, useLazyQuery } from "@apollo/client";
import { Loading } from "components/Loading/Loading";
import { loader } from "graphql.macro";
import { stateUser, stateCompany } from "graphql/state";
import { Button, Input } from "components/FormComponents";
import { useForm } from "react-hook-form";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { useAppContext } from "templates/AppLayout/AppLayout";
import styles from "./Users.module.scss";
import jwt from "jwt-decode";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { UserRow } from "./UserRow/UserRow";
import fuzzysort from "fuzzysort";
import { AppLayout } from "templates/AppLayout/AppLayout";

const USERS_QUERY = loader("./../../../../graphql/queries/users.graphql");
const IMPERSONATE_MUTATION = loader("./../../../../graphql/mutations/impersonate_user.graphql");
const REMOVE_IMPERSONATION_MUTATION = loader("./../../../../graphql/mutations/remove_impersonation.graphql");

export const Users = () => {
  const { alert, triggerUnexpectedError } = useAppContext();

  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);

  const [user, setUser] = useState();
  const [company, setCompany] = useState();
  const [filtered, setFiltered] = useState();

  const navigate = useNavigate();

  const currentUser = useReactiveVar(stateUser);
  const currentCompany = useReactiveVar(stateCompany);
  const impersonatorId = Cookies.get("token") ? jwt(Cookies.get("token"))?.impersonator_id : null;

  const { register, watch } = useForm();

  const [fetchUsers, { loading: fetchingUsers }] = useLazyQuery(USERS_QUERY, {
    onCompleted: (data) => {
      setUsers(data.users);
      setFiltered(data.users);
      setLoading(false);
    },
    onError: (error) => triggerUnexpectedError(error.message),
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    if (currentUser) {
      setUser(currentUser);
      setCompany(currentCompany);
      fetchUsers();
    }
  }, [user, company, stateUser, stateCompany, impersonatorId]);

  const searchName = watch("searchName");

  useEffect(() => {
    if (searchName !== null && searchName?.trim() !== "") {
      const filter = fuzzysort.go(searchName, users, { keys: ["name", "email", "company.name"], threshold: -10000 });
      const result = [];
      filter.forEach((e) => {
        result.push(e.obj);
      });
      setFiltered(result);
    } else {
      fetchUsers();
    }
  }, [searchName]);

  const client = useApolloClient();

  const [startImpersonation] = useMutation(IMPERSONATE_MUTATION, {
    onCompleted: async (data) => {
      const { token, user, company } = data.impersonateUser;
      await client.clearStore();
      Cookies.set("token", token, { path: "" });
      stateCompany(company);
      stateUser(user);
      setLoading(false);
      navigate("/");
    },
    onError: (error) => {
      triggerUnexpectedError(error.message);
      setLoading(false);
    },
  });

  const [endImpersonation] = useMutation(REMOVE_IMPERSONATION_MUTATION, {
    onCompleted: async (data) => {
      const { token, user, company } = data.removeImpersonation;
      await client.clearStore();
      Cookies.set("token", token, { path: "" });
      setLoading(false);
      stateCompany(company);
      stateUser(user);
      alert("User impersonation ended");
    },
    onError: (error) => {
      triggerUnexpectedError(error.message);
      setLoading(false);
    },
  });

  const revertImpersonation = async () => {
    setLoading(true);
    await endImpersonation({ variables: { impersonater: `${impersonatorId}` } });
  };

  if (loading) return <Loading visible={loading} />;

  const handleImpersonation = async (id) => {
    setLoading(true);
    await startImpersonation({ variables: { userId: `${id}` } });
  };

  return (
    <>
      {currentUser && !impersonatorId ? (
        <>
          <AppLayout.Header className={cn(styles.header, "card-with-border mb-5", styles.border)}>
            <div className="d-flex justify-content-start">
              <form className={cn(styles.searchForm)}>
                <Input name="searchName" register={register} type="text" icon="search" placeholder="Search name, email or company" />
              </form>
            </div>
          </AppLayout.Header>
          <div className={cn(styles.border, "card-with-border")}>
            {!!filtered?.length ? (
              <>
                <div className={cn(styles.tableHeaders)}>
                  <span className={cn(styles.statusParent, styles.filter, "d-flex align-items-center")}>Name</span>
                  <span className={cn("d-flex align-items-center")}>Company</span>
                  <span className={cn("d-flex align-items-center")}>Email</span>
                  <span className={cn("d-flex align-items-center")}>Verified</span>
                  <span className={cn("d-flex align-items-center")}>Actions</span>
                </div>
                <div className={styles.tableRows}>
                  {filtered?.map((user) => {
                    return <UserRow user={user} key={user?.id} handleImpersonation={handleImpersonation} />;
                  })}
                </div>
              </>
            ) : (
              <div className={styles.notFound}> User Not Found </div>
            )}
          </div>
        </>
      ) : (
        <div className={styles.button}>
          <Button onClick={revertImpersonation}> End Impersonation </Button>
        </div>
      )}
    </>
  );
};
