import { Button, Input, InputGroup } from "components/FormComponents";
import { useForm } from "react-hook-form";
import styles from './ExternalProfileForm.module.scss';
import cn from 'classnames';
import { useEffect } from "react";
import { useReactiveVar } from "@apollo/client";
import { stateCompany } from "graphql/state";
import { ModalForm } from "components/ModalForm/ModalForm";

export const ExternalProfileForm = ({ externalStakeholder, updateUser, onClose, visible }) => {

  const company = useReactiveVar(stateCompany);

  const { register, errors, reset, handleSubmit } = useForm({defaultValues: {
    firstName: externalStakeholder.firstName,
    lastName: externalStakeholder.lastName,
    phoneNumber: externalStakeholder.phoneNumber
  }});

  const close = () => {
    onClose()
    reset()
  }
  
  const onSubmit = (data) => {
    updateUser({
      variables: {
        externalStakeholderAttributes: { id: externalStakeholder.id, ...data },
        companyId: company.id
      }
    })
    onClose()
  }
  
  return (
    <ModalForm visible={visible} onClose={() => {onClose()}}>
      <form className={cn('card', styles.root)} onSubmit={handleSubmit(onSubmit)}>
        <div className='card_content'>
          <h1 className={cn(styles.title, 'title-3 mb-5')}>Edit profile</h1>
          <InputGroup className={styles.input} title='First Name'>
            <Input 
              name='firstName'
              type='text'
              placeholder='Enter your first name'
              register={register}
              error={errors?.firstName?.type}
              inputProps={{ tabIndex: 1 }}
            />
          </InputGroup>
          <InputGroup className={styles.input} title='Last Name'>
            <Input 
              name='lastName'
              type='text'
              placeholder='Enter your last name'
              register={register}
              error={errors?.lastName?.type}
              inputProps={{ tabIndex: 2 }}
            />
          </InputGroup>
          <InputGroup className={styles.input} title='Phone Number'>
            <Input 
              name='phoneNumber'
              type='text'
              placeholder='Enter your new phone number'
              register={register}
              error={errors?.phoneNumber?.type}
              inputProps={{ tabIndex: 3 }}
            />
          </InputGroup>
        </div>
        <div className={cn(styles.noBorder, 'card_footer', 'd-flex', 'justify-content-between')}>
          <Button type='secondary' onClick={close}>Cancel</Button>
          <Button submit>Save</Button>
        </div>
      </form>
    </ModalForm>
  )
}
