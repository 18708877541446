import React from "react";
import { useCallback, useState } from "react";
import { Badge, Dropdown, ListGroup } from "react-bootstrap";
import moment from "moment";
import cn from "classnames";
import { Button } from "components/FormComponents";
import { IconButton } from "components/IconButton/IconButton";
import styles from "./List.module.scss";
import { Collapse } from "react-bootstrap";
import { QuestionnaireBlockResult } from "views/StakeholderProfileScreen/tabs/Journeys/components/QuestionnaireBlockResult/QuestionnaireBlockResult";
import { InformationPreview } from "views/NewStakeholderJourneyScreen/components/JourneyPreview/InformationPreview/InformationPreview";
import { DocumentBlockResult } from "views/StakeholderProfileScreen/tabs/Journeys/components/DocumentBlockResult/DocumentBlockResult";
import { BundleBlockResult } from "views/StakeholderProfileScreen/tabs/Journeys/components/BundleBlockResult/BundleBlockResult";
import download from 'downloadjs';
import { saveAs } from "file-saver";
import { ConfirmationModal } from "components/ConfirmationModal/ConfirmationModal";
import { QuizBlockResult } from "views/StakeholderProfileScreen/tabs/Journeys/components/QuizBlockResult/QuizBlockResult";

export const List = ({ setOpenModal, openModal, setDeleteId, getCsv, allBlockResults, journeyBlocks, internalStakeholders, optionalDocuments, handleReset }) => {

  const [blockResults, setBlockResults] = useState(allBlockResults)

  const [open, setOpen] = useState(null);
  const [resetModal, setResetModal] = useState(false)
  const [result , setResult] = useState(null)

  const onClose = () =>{
    setResult(null)
    setResetModal(false)
  }
  const blockResult = useCallback(
    (block) => {
      return blockResults.find((blockResult) => blockResult.blockId === block.id);
    },
    [blockResults]
  );

  const deleteResult=(result)=>{
    const index = blockResults?.findIndex((br)=>br?.id === result.id)
    if(index !== -1){
      const results = blockResults
      results[index].status = 'pending'
      results[index]?.answers?.forEach((answer)=>{
        delete answer?.value
        delete answer?.fieldId
        delete answer?.upload
        delete answer?.id
      })
      setBlockResults(results)      
    }
  }

  const renderBlockTypes = (block, blockResult, journeyBlocks=null) => {

    const stakeholders = internalStakeholders?.find((s)=> s.stakeholderJourneyId === blockResult.stakeholderJourneyId )?.internalStakeholder?.map((s)=>s)
    const internalStakeholder =  stakeholders?.find((s)=> (s?.stakeholderTag?.typeOf === blockResult?.block?.stakeholderTag?.typeOf) && (s?.stakeholderTag?.id === blockResult?.block?.stakeholderTag?.id))  

    switch (block?.typeOf) {
      case "document":
        return <DocumentBlockResult block={block} blockResult={blockResult} />;

      case "information":
        return <InformationPreview block={block} internalStakeholder={internalStakeholder} />;

      case "questionnaire":
        const otherBlockResults = blockResults.filter(bResult => bResult.id !== blockResult.id && bResult.block.typeOf === 'questionnaire');
        return <QuestionnaireBlockResult block={block} blockResult={blockResult} internalStakeholder={internalStakeholder} otherBlockResults={otherBlockResults} journeyBlocks={journeyBlocks}/>;

      case "bundle":
        return <BundleBlockResult blockResultId={blockResult.id}/>

      case "quiz":
        return <QuizBlockResult block={block} blockResult={blockResult} internalStakeholder={internalStakeholder}/>
    }
  };

  return (
    <>
        <ConfirmationModal
          title='Reset block data'
          buttonTitle='Reset'
          subtitle='are you sure you want to delete the filled information form this block?'
          visible={resetModal}
          onConfirmation={() => {
            deleteResult(result)
            handleReset(result)
            onClose()
          }}
          onClose={() => onClose() }
        />
      {!!blockResults?.length &&
        blockResults.map((result, index) => {
          return (
            <div class="mb-4">
              <ListGroup>
                <ListGroup.Item
                  className={cn(`${styles.listItem}  list-item--success d-flex justify-content-between`)}
                  style={{ borderLeft: `4px solid ${journeyBlocks[index]?.[0]?.block?.stakeholderTag?.color}` }}
                >
                  <div>
                    <div className={cn("d-flex")}>
                      <p className="title-4 font-weight-500">{result?.block?.name}</p>
                      <Badge className={cn("margin-left--small", "t-capitalize", `badge--${result.status}`)}>
                        {result.status.replace(/_/g, "-")}
                      </Badge>
                    </div>
                    <p className={cn(styles.date) + " pt-2"}>{moment(result.updatedAt).format("MMM DD YYYY")}</p>
                    <Collapse in={index === open}>
                      <div>{renderBlockTypes(journeyBlocks[index]?.[0]?.block, result, journeyBlocks)}</div>
                    </Collapse>
                  </div>
                  <div>
                    <div className={cn("d-flex align-items-center")}>
                      {result.resultUrl && (
                        <Button
                          type="secondary"
                          className={cn(`${styles.viewBtn} me-3`)}
                          onClick={() => {
                            const blockName = result?.block?.name?.replace(/\s+/g, '-')
                            const name = result?.documentSigners?.[0]?.stakeholder?.name?.replace(/\s+/g, '-')
                            download(result.resultUrl, `${name}-${blockName}.pdf`, "application/pdf")
                          }}
                        >
                          View
                        </Button>
                      )}
                   
                      {['questionnaire','quiz'].includes(result?.block?.typeOf) &&
                        <IconButton
                        className={cn(styles.collapseToggle)}
                          icon="rotate-ccw"
                          onClick={()=> 
                            {
                              setResult(result)
                              setResetModal(true)
                            }
                          }
                        />
                      }
                      <IconButton
                        className={cn(styles.collapseToggle)}
                        icon={`chevron-${index === open ? "up" : "down"}`}
                        onClick={() => setOpen(index === open ? null : index)}
                      />

                      <Dropdown>
                        <Dropdown.Toggle className={cn(styles.dropdownToggle)}>
                          <IconButton className={cn(styles.moreBtn)} icon="more-vertical" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={cn(styles.dropdownMenu)}>
                          {(['questionnaire','quiz'].includes(result?.block?.typeOf) || result.resultUrl) && (
                            <Dropdown.Item
                              className={cn(styles.menuItem)}
                              href="#"
                              onClick={() => {
                                if(result.resultUrl){
                                  const blockName = result?.block?.name?.replace(/\s+/g, '-')
                                  const name = result?.documentSigners?.[0]?.stakeholder?.name?.replace(/\s+/g, '-')
                                  download(result.resultUrl, `${name}-${blockName}`, "application/pdf")
                                }else{
                                  getCsv([{id: result.id}]);
                                }                  
                              }}
                            >
                              Download
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item
                            className={cn(styles.menuItem)}
                            href="#"
                            onClick={() => {
                              setOpenModal(!openModal);
                              setDeleteId(result.id);
                            }}
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  </ListGroup.Item>
              </ListGroup>
            </div>
          );
        })
      }
      { 
      !!optionalDocuments?.length && optionalDocuments?.map((documents)=>{
        return documents?.map((document)=>{
          return(
            <div className="mb-4">
              <ListGroup>
                  <ListGroup.Item
                  className={cn(`${styles.listItem}  list-item--success d-flex justify-content-between`)}
                  style={{ borderLeft: `4px solid red` }}
                  >
                    <div>
                      <div className={cn("d-flex")}>
                        <p className="title-4 font-weight-500">{document?.documentUpload?.filename || document?.signatureUpload?.filename}</p>
                        <Badge className={cn("margin-left--small", "t-capitalize", `badge--completed`)}>
                          Nice to do
                        </Badge>
                      </div>
                      <p className={cn(styles.date) + " pt-2"}>{moment(document?.updatedAt).format("MMM DD YYYY")}</p>
                    </div>
                    <div>
                      <Dropdown>
                        <Dropdown.Toggle className={cn(styles.dropdownToggle)}>
                          <IconButton className={cn(styles.moreBtn)} icon="more-vertical" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={cn(styles.dropdownMenu)}>
                          <Dropdown.Item
                          className={cn(styles.menuItem)}
                          href="#"
                          onClick={()=>{
                            const doc = document?.documentUpload || document?.signatureUpload
                            saveAs(doc?.url , doc?.filename)
                          }}
                          >
                            Download
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    </ListGroup.Item>
              </ListGroup>
            </div>
          )
        })
      })
      }
    </>
  );
};
