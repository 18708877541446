import { Input, FilePicker, MultipleSelect, Button } from 'components/FormComponents';
import { Controller, useWatch } from 'react-hook-form';
import { useFieldArray } from 'react-hook-form';
import styles from './DocumentSigningBuilder.module.scss';
import cn from 'classnames';
import { useEffect,useState } from 'react';
import { useAppContext } from 'templates/AppLayout/AppLayout';
import { loader } from "graphql.macro";
import { useLazyQuery} from "@apollo/client";
import { ModalForm } from 'components/ModalForm/ModalForm';
import { IconButton } from 'components/IconButton/IconButton';

const TEMPLATE_EDIT_QUERY = loader('./../../../../../../../graphql/queries/signeasy_template_embed_url.graphql')

export const DocumentSigningBuilder = ({
  block,
  saved,
  setSaved,
  control,
  register,
  stakeholderTags,
  errors,
  clearErrors,
  setError,
  handleRemoveDocument = () =>{}
}) => {

  const [visible , setVisible] = useState(false)
  const [disableConfig, setDisableConfig] = useState(false)
  const [signeasyTemplateUrl, setSigneasyTemplateUrl] = useState(null)
  const [totalPages , setTotalPages] = useState(1)
  const documentTemplate = useWatch({ control, name: 'documentTemplateAttributes' })
  const watchFields = useWatch({control, name: `documentTemplateAttributes.customFields`})
  const { setLoading, triggerUnexpectedError } = useAppContext();
  var errorCount = 0
  errors?.documentTemplateAttributes?.customFields?.forEach((field)=>{
    errorCount = field ? errorCount + 1 : errorCount
  })
  if (errorCount === 0 && errors?.documentTemplateAttributes?.customFields?.length){
    clearErrors('documentTemplateAttributes.customFields')
  }

  const { fields, append, remove } = useFieldArray({ control, name: `documentTemplateAttributes.customFields` });

  const [fetchTemplateUrl, { loading: fetchingTemplateUrl }] = useLazyQuery(TEMPLATE_EDIT_QUERY, {
    onCompleted: data => {
      setSigneasyTemplateUrl(data.signeasyTemplateEmbedUrl)
      setVisible(true)
    }
  });


  const addField = () => {
    append({ name: '', pageNumber: '' })
    setDisableConfig(true)
  }

  const openSignEasy = () =>{
    fetchTemplateUrl({variables: {documentTemplateId: documentTemplate.id }})
  }

  const onClose=()=>{
    setVisible(false)
    setSigneasyTemplateUrl(null)
  }

  useEffect(() => {
    if (saved) {
      setLoading(true)
      const timer = setTimeout(() => {
        setLoading(false);
        setSaved(false);
      }, 3000);
      return () => clearTimeout(timer);
    }

  },[block])

  useEffect(()=>{
    const names = watchFields?.map((field)=> field?.name)
    fields?.forEach((f,index)=>{
      names?.some((e, i, arr) => arr.indexOf(e) !== i) ?
      setError(`documentTemplateAttributes.customFields.${index}.name`,{type: 'Fields Must Not Match'})
      : clearErrors(`documentTemplateAttributes.customFields.${index}.name`)
    })
  },[watchFields])

  useEffect(()=>{
    setLoading(fetchingTemplateUrl)
  },[fetchingTemplateUrl])

  return (
    <div className='w-100 d-flex align-items-end flex-column'>
      <ModalForm className={styles.modal} visible={visible && signeasyTemplateUrl} onClose={onClose}>
        <iframe
          src={signeasyTemplateUrl}
          style={{width: '1000px', height: '500px'}}
        />

      <div className={cn('card_footer', styles.noBorder, 'd-flex', 'justify-content-center')}>
        <Button type='secondary' onClick={onClose} >Close</Button>
      </div>
      </ModalForm>
      <div className='card card-with-border w-100'>
        <div className='card_content align-items-start'>
          <Input
            register={register}
            name='documentTemplateAttributes.prompt'
            className='w-100'
            validators={{ required: true }}
            error={errors?.documentTemplateAttributes?.prompt?.type}
            noErrorMessage
            placeholder='Your question/prompt here...'
          />
          <Controller
            control={control}
            name="documentTemplateAttributes.documentTemplatesStakeholderTagsAttributes"
            render={({ field: { onChange, value } }) => (
              <MultipleSelect value={value} onChange={onChange} valueKey="stakeholderTagId" placeholder='Other signers' className='margin-top'>
                {stakeholderTags.map(stakeholderTag => (
                  <MultipleSelect.Item key={stakeholderTag.id} value={stakeholderTag.id} active={value?.map(docTempStakeTag => docTempStakeTag.stakeholderTagId).includes(stakeholderTag.id)}>
                    {stakeholderTag.name}
                  </MultipleSelect.Item>
                ))}
              </MultipleSelect>
            )}
          />
          <hr className='margin-top' />
          <p className='t-body margin-bottom'>Upload the documents that need to be signed below</p>
          <Controller
            control={control}
            name='documentTemplateAttributes.fileId'
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <FilePicker
                value={value}
                disabled={block?.usedInStakeholderJourney}
                onChange={onChange}
                error={errors?.documentTemplateAttributes?.fileId?.type}
                onCustomRemove={handleRemoveDocument}
                acceptedFileExtension={"pdf"}
                setError={setError}
                name='documentTemplateAttributes.fileId'
                clearErrors={clearErrors}
                setPages={setTotalPages}
                checkPages
              />
            )}
          />
          <hr className='margin-top' />
          <p className='t-body margin-bottom'>Add custom fields description below</p>
          <div className='margin-bottom--small card card-with-border background-secondary px-4 w-100'>
            {fields.map((_, index) => (
              <div className={styles.customFields}>
                <Input
                  key={index}
                  register={register}
                  name={`documentTemplateAttributes.customFields.${index}.name`}
                  className={styles.input}
                  error={errors?.documentTemplateAttributes?.customFields?.[index]?.name?.type}
                  validators={{ required: true}}
                  placeholder='Enter custom field name...'
                />
                <Input
                  key={index}
                  register={register}
                  name={`documentTemplateAttributes.customFields.${index}.pageNumber`}
                  className={styles.input}
                  error={errors?.documentTemplateAttributes?.customFields?.[index]?.pageNumber?.message || errors?.documentTemplateAttributes?.customFields?.[index]?.pageNumber?.type}
                  validators={{ required: true,
                    max: {
                      value: totalPages,
                      message: `Page number must not exceed total pages (${totalPages})`,
                    },
                    min: {
                      value: 1,
                      message: 'Page number cannot be 0'
                    }
                  }
                  }
                  type='number'
                  placeholder='Enter page number for this field'
                />
                <IconButton className={styles.actionButton} tip={'Delete Fields'} icon={'trash-2'} onClick={() => {
                    remove(index);
                    setDisableConfig(true)
                }} />
              </div>
            ))}
            <Button type='link' onClick={addField}>+ Custom field</Button>
          </div>
          <hr className='margin-top' />
          <Button
            icon='edit-2'
            type='secondary-reverse'
            className={cn(styles.configure, 'margin-top')}
            disabled={!documentTemplate?.id || disableConfig||
              (documentTemplate?.fileId !== block?.documentTemplateAttributes?.fileId)}
            onClick={openSignEasy}
          >
            Configure
          </Button>
        </div>
      </div>
    </div>

  )
}