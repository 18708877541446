import styles from './CollapsibleBlock.module.scss';
import { Collapse } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { IconButton } from '../../components/IconButton/IconButton';
import { useState, useContext, createContext } from 'react';
import cn from 'classnames';
import { useEffect } from 'react';

const CollapsibleGroupContext = createContext(null);

export const CollapsibleGroup = ({ newStartOpen, children }) => {

  const [selectedKey, setSelectedKey] = useState();

  const contextValue = ({ selectedKey, newStartOpen, setSelectedKey });

  return (
    <CollapsibleGroupContext.Provider value={contextValue}>
      {children}
    </CollapsibleGroupContext.Provider>
  )
}

const CollapsibleContext = createContext(null);

export const CollapsibleBlock = ({
  keyCode,
  children, 
  color,
  className, 
  startOpen,
  open,
  headerClassName,
  actionComponent
}) => {

  const collapsibleGroup = useContext(CollapsibleGroupContext);

  // Set key if in CollapsibleGroup and opened to close other Blocks
  useEffect(() => {
    if(!collapsibleGroup) return;
    setOpened(collapsibleGroup?.selectedKey === keyCode)
  }, [collapsibleGroup?.selectedKey]);

  // Open on creation if property set on CollapsibleGroup
  useEffect(() => {
    if(!keyCode || !collapsibleGroup || !collapsibleGroup.newStartOpen) return;
    handleToggle();
  }, [])

  const [opened, setOpened] = useState(startOpen);
  const [hasContent, setHasContent] = useState(false);

  const handleToggle = () => {
    if(open){return}
    if(!opened && collapsibleGroup) collapsibleGroup.setSelectedKey(keyCode);
    setOpened(!opened);
  }

  const contextValue = ({ opened, hasContent, onToggle: handleToggle, setHasContent, headerClassName, actionComponent });

  useEffect(() => {
    if(open) {setOpened(open)}
  }, [open])

  return (
    <CollapsibleContext.Provider value={contextValue}>
      <div className={cn(styles.root, 'card', 'card-with-border', { [styles.rootOpened]: opened, [className]: className })} style={{ borderLeft: `3px solid ${color}` }}>
        {children}
      </div>
    </CollapsibleContext.Provider>
  )
}

const Header = ({ children, fullWith }) => {
  const { onToggle, hasContent, headerClassName, actionComponent } = useContext(CollapsibleContext);

  return (
    <header className={cn(styles.header, { [styles.headerFullWith]: fullWith }, {[headerClassName]: headerClassName})}>
      {children}
      <div className={cn(styles.icons, { [styles.iconsWithAction]: actionComponent })}>
        {actionComponent}
        {hasContent &&
          <IconButton className={styles.toggleButton} icon='chevron-down' onClick={onToggle}/>
        }
      </div>
    </header>
  )
}

const Content = ({ children, className }) => {
  const { opened, setHasContent } = useContext(CollapsibleContext)

  useEffect(() => setHasContent(!!children), [children])

  return (
    <Collapse in={opened}>
      <div className={className}>{children}</div>
    </Collapse>
  )
}

CollapsibleBlock.Header = Header;
CollapsibleBlock.Content = Content;

CollapsibleBlock.propTypes = {
  keyCode: PropTypes.string,
  className: PropTypes.string,
  startOpen: PropTypes.bool,
  color: PropTypes.string
};

CollapsibleBlock.defaultProps = {
  startOpen: false,
};
