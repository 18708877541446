import React, { useState, useEffect } from "react";
import { loader } from "graphql.macro";
import { useLazyQuery } from "@apollo/client";
import { Select, Button } from "components/FormComponents";
import { Controller, useFieldArray, useWatch } from "react-hook-form";
import styles from "./ExistingStakeholdersSelector.module.scss";
import cn from "classnames";
import { IconButton } from "components/IconButton/IconButton";
import { ReactSelect } from "components/FormComponents/ReactSelect/ReactSelect";

const EXTERNAL_STAKEHOLDERS_QUERY = loader("./../../../../../../graphql/queries/external_stakeholders_with_deleted.graphql")

export const ExistingStakeholdersSelector = ({ setCsvData, control, register, errors, unregister, setError, clearErrors, isInline, emptyError, setLoading=()=>{}, triggerUnexpectedError }) => {

  const [fetchedExternalStakeholders, setFetchedExternalStakeholders] = useState([]);
  const { fields, append, remove } = useFieldArray({ control, name: `externalStakeholderIds` });
  const watchFields = useWatch({ control, name: `externalStakeholderIds` });

  const [fetch, { loading: fetching }] = useLazyQuery(EXTERNAL_STAKEHOLDERS_QUERY, {
    onCompleted: (data) => {
      setFetchedExternalStakeholders(data.externalStakeholdersWithDeleted);
      if(watchFields.length === 0)append({ id: "" });
    },
    onError: () => {
      triggerUnexpectedError();
    },
  });

  useEffect(() => {
    setLoading(fetching);
  }, [fetching]);

  useEffect(() => {
    if (isInline) setCsvData(null)
    fetch();
    clearErrors();
    unregister(`externalStakeholderAttributes`)
    unregister(`externalStakeholders`)
  }, []);

  useEffect(()=>{
    watchFields?.length ===0 ? emptyError && setError('externalStakeholderIds.empty',{type: `Must have atleast 1 field`}) : clearErrors('externalStakeholderIds.empty')
    watchFields?.forEach((field,index)=>{
      const errorString = `stakeholderJourneyAttributes.externalStakeholderIds.${index}`
      field?.id === "" ? setError(errorString, {type:`required`})  : clearErrors(errorString)
    })
    var customError = 0
    errors?.stakeholderJourneyAttributes?.externalStakeholderIds?.forEach((error)=>{
      customError= error ? customError+1 : customError
    })
    if (!customError) clearErrors(`stakeholderJourneyAttributes.externalStakeholderIds`)
  },[watchFields])

  const stakeholderOptions = fetchedExternalStakeholders?.map((stakeholder)=>{
    {return {label: `${stakeholder?.firstName} ${stakeholder.lastName} ${stakeholder?.email}`, value: stakeholder}}})

  return (
    <>
      <div className={styles.card}>
        {fields?.map((externalStakeholder, index) => {
          return (
            <div className={cn(styles.row)} key={index}>
              <div className={cn(styles.select)}>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name={`externalStakeholderIds.${index}`}
                  render={({ field: { onChange, value } }) => (
                    <div className={cn(styles.item)}>
                    <ReactSelect
                      isSearchable
                      options={stakeholderOptions}
                      value={value}
                      onChange={onChange}
                      placeholder="Select Existing Stakeholder"
                      backgroundColor={"#FFFFFF"}
                    />
                    </div>
                  )} />
              </div>
              <IconButton tip='Delete Field' className={styles.remove} onClick={() =>{
                clearErrors()
                remove(index)}} icon='trash-2' />
          </div>
            );
        })}
        <Button type="link" onClick={() => { append({ id: "" }) }}>
          + Add Another Employee
        </Button>
      </div>
      {!!errors?.externalStakeholderIds?.empty &&
        <div className={cn(styles.error)}>{ errors?.externalStakeholderIds?.empty?.type}</div>
      }
  </>
  );
};
